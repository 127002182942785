<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['invoices_Invoice',])"></div>
      <CardLeftBlock
              :name="''"
              :value="''"
              :backgroundImage="'shipments'"
      >
        <template slot="button">
          <h2 class="mt-0">{{$t('invoices_Invoice.localization_value.value')}}</h2>
          <div class="card-detail-left__date">
            {{ new Date() | moment("DD MMM, YYYY") }}
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['invoices_BackToInvoices',])"></div>
                <router-link :to="$store.getters.GET_PATHS.financeInvoices" class="order-create__head-back">
                  <LinkBack
                          :value="$t('invoices_BackToInvoices.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['payPalCases_createCase',])"></div>
              {{$t('invoices_createInvoice.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
            <div class="custom-row">
              <div class="custom-col custom-col--33 custom-col--md-100">
                <UserSelect
                    :validationUser="invoice.data.User.validation.userId"
                    :validationTranslateUser="invoice.data.User.validationTranslate.userId"
                    :userSelected="invoice.data.User.user"
                    :typeSelect="'users'"
                    :userEmail="true"
                    @changeUser="changeUser"
                />
              </div>
            </div>

            <FieldsBlock
              :invoice="invoice"
              :statuses="statuses"
              :disableFieldsFlag="false"
            />
            <FilesBlock
                :invoice="invoice"
            />

          </div>
        </template>

        <template slot="footer">
          <div class="order-create__footer">

            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_cancel',])"></div>
              <router-link :to="$store.getters.GET_PATHS.financeInvoices"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>
            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_create'])"></div>

              <MainButton
                      class="order-create__footer-btn-i"
                      :value="$t('common_create.localization_value.value')"
                      @click.native="$emit('save', false, 'open')"
                      v-bind:class="{'disabled-btn' : $store.getters.getInvoicesBtn}"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import FieldsBlock from "../../../../InvoicesModule/components/chunks/FieldsBlock";
  import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
  import FilesBlock from "../../chunks/FilesBlock";

  export default {
    name: "InvoicesCreationSection",

    components: {
      FilesBlock,
      UserSelect,
      FieldsBlock,
      CardLeftBlock,
      MainButton,
      CardRightBlock,
      LinkBack,
    },

    props: {
      invoice: Object,
      statuses: Array,
    },

    data() {
      return {

      }
    },

    methods: {
      changeUser(val) {
        this.invoice.data.User.setUser(val)
      },
    }
  }
</script>

<style lang="scss">

</style>
