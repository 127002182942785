<template>
  <InvoicesCreationSection
        :invoice="invoice"
        :statuses="statuses"
        @save="save"
  />
</template>

<script>
  import InvoicesCreationSection from "./InvoicesCreationSection/InvoicesCreationSection";
  import {Invoices} from "../models/Invoices";
  import {invoicesMixin} from "../../../../../mixins/invoicesMixins/invoicesMixin";
  import {INVOICES_STATUS_PAYED, INVOICES_STATUS_DRAFT} from "../../../../../staticData/staticVariables";

  export default {
    name: "InvoicesCreation",

    components:{
      InvoicesCreationSection,
    },

    mixins: [invoicesMixin],

    data() {
      return {
        invoice: new Invoices(),
        statuses: [
            INVOICES_STATUS_DRAFT,
            INVOICES_STATUS_PAYED
        ]
      }
    },

    mounted() {
      if (!this.isAdmin) {
        this.$router.push(this.$store.getters.GET_PATHS.notFound)
      }
    },

    methods: {
    }

  }
</script>

<style scoped>

</style>
